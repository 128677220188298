import React from 'react';
import axios from 'axios';
import {connect} from 'react-redux';

import {extractTwitchUserLogin} from './twitchUtils.js';
import Entity from "./Entity";

class Items extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.liveStatusInterval = null;
    }

    componentDidMount() {
        if (this.props.type === Entity.STREAM && !this.liveStatusInterval && this.getRelevantItems().length > 0) {
            this.liveStatusInterval = this.startLiveStatusPolling();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.type === Entity.STREAM && !this.liveStatusInterval && this.getRelevantItems().length > 0) {
            this.liveStatusInterval = this.startLiveStatusPolling();
        }
    }

    componentWillUnmount() {
        if (this.liveStatusInterval) {
            clearInterval(this.liveStatusInterval);
            this.liveStatusInterval = null;
        }
    }

    getUserLogins() {
        return this.getRelevantItems()
            .map(item => extractTwitchUserLogin(item))
            .filter(match => !!match)
            .map(userLogin => 'user_login=' + encodeURIComponent(userLogin))
            .join('&');
    }

    startLiveStatusPolling() {
        // Initial fetch.
        this.pollLiveStatus();

        // Polling every minute.
        return setInterval(() => {
            this.pollLiveStatus();
        }, 60 * 1000);
    }

    pollLiveStatus() {
        // Only do this for stream entities.
        if (this.props.type !== Entity.STREAM) {
            return;
        }

        const relevantItems = this.getRelevantItems();

        // No streams added yet? Stop.
        if (!relevantItems || relevantItems.length === 0) {
            return;
        }

        axios.get('https://pursuit-ws.poe-racing.com/live-status?' + this.getUserLogins())
            .then((response) => {
                // Did not receive a list of streams. Terminate early.
                if (!response || !response.data || !response.data.length) {
                    return;
                }

                const { dispatch } = this.props;

                // Update item list in state with live status.
                dispatch({
                    type: 'SET_LIVE_STATUS',
                    data: response.data
                });

                // Refresh the visible list after a second. Give it some time...
                setTimeout(() => {
                    dispatch({
                        type: 'REFRESH_VISIBLE_ITEMS'
                    });
                }, 1000);
            });
    }

    renderItems() {
        return this.getRelevantItems()
            .map(item => {
                let classes = ['item', 'ns', item.type.toLowerCase()];

                if (this.props.selectedItems.indexOf(item) >= 0) {
                    classes.push('selected');
                }

                if (item.type === Entity.STREAM && item.live === false) {
                    classes.push('not-live');
                }

                return (
                    <div onDragStart={e => this.onDragStart(e, item)}
                         onDragEnd={e => this.onDragEnd(e, item)}
                         onClick={e => this.onToggleSelect(item)}
                         draggable={true}
                         title={classes.indexOf('not-live') >= 0 ? 'Stream is offline' : null}
                         className={classes.join(' ')}>
                        <span className="title">{item.title}</span>
                        <span className="type">{item.type === Entity.STREAM && item.live === false ? 'OFFLINE' : item.type}</span>
                    </div>
                );
            });
    }

    getRelevantItems() {
        return this.props.items
            .filter(item => item.type === this.props.type);
    }

    onToggleSelect(item) {
        const {dispatch} = this.props;

        dispatch({
            type: this.props.selectedItems.indexOf(item) >= 0 ? 'DESELECT_ITEM' : 'SELECT_ITEM',
            item
        });
    }

    onDragStart(e, item) {
        e.dataTransfer.setData('item-data', JSON.stringify({
            data: {
                title: item.title,
                url: item.url,
                muted: !this.props.broadcastState.streamSettings.volume,
                fullscreen: false,
                quality: this.props.broadcastState.streamSettings.quality,
                volume: this.props.broadcastState.streamSettings.volume
            },
            type: item.type
        }));

        e.dataTransfer.dropEffect = 'move';
    }

    onDragEnd(e, entity) {
        e.preventDefault();
    }

    render() {
        let classes = ['items'];

        classes.push(this.props.type.toLowerCase());

        if (this.getRelevantItems().length > 0) {
            classes.push('has-items');
        }

        return (
            <div className={classes.join(' ')}>
                {this.renderItems()}
            </div>
        );
    }
}


let mapStateToProps = (state) => {
    return {
        itemSettings: state.itemSettings,
        items: state.visibleItems,
        selectedItems: state.selectedItems,
        broadcastState: state.broadcastState
    };
};

export default connect(mapStateToProps)(Items);